import React, { useEffect, useContext, useMemo } from "react";

import {
  SlBookOpen,
  SlCalender,
  SlList,
  SlPicture,
  SlPresent,
  SlGrid,
  SlBriefcase,
  SlKey,
  SlUserFollow,
  SlGraph,
  SlBubbles,
  SlEnvolope,
  SlFilm,
  SlNotebook,
  SlBasket,
  SlPencil,
  SlUser,
  SlInfo,
  SlSupport,
  SlChart,
  SlBadge,
  SlHome,
  SlHandbag,
} from "react-icons/sl";
import { CurrentUserContext } from "../6_contextDB/UserContext";
import MainTemplate from "../16_pages/MainTemplate";
const ICON_SIZE = { width: "15px", height: "15px" }; // Fixed size for icons
function HomeComponent(props) {
  useEffect(() => {
    document.title = "跑动英伦";
  }, []);
  const [
    currentUser,
    setCurrentUser,
    businessUser,
    setBusinessUser,
    businessDB,
    setBusinessDB,
    userDetail,
  ] = useContext(CurrentUserContext);

  const menuItems = useMemo(
    () => [
      {
        title: "Calendar",
        icon: <SlCalender style={ICON_SIZE} />,
        link: "/eventcalendar",
      },
      {
        title: "Events",
        icon: <SlList style={ICON_SIZE} />,
        link: "/events",
      },
      {
        title: "Momemt",
        icon: <SlSupport style={ICON_SIZE} />,
        link: "/activitywall",
      },
      {
        title: "Photos",
        icon: <SlPicture style={ICON_SIZE} />,
        link: "/ShowPhotoList",
      },
      {
        title: "Market Place",
        icon: <SlHandbag style={ICON_SIZE} />,
        link: "/productlist",
      },


      {
        title: "Member Zone",
        icon: <SlGrid style={ICON_SIZE} />,
        link: "/clubmain",
      },
      {
        title: "Entrepreneur Zone",
        icon: <SlBriefcase style={ICON_SIZE} />,
        link: "/businessmain",
      },
      {
        title: "Property",
        icon: <SlHome style={ICON_SIZE} />,
        link: "/PropertyPriceData",
      },

 


      { title: "Posts", icon: <SlNotebook style={ICON_SIZE} />, link: "/blog" },
      {
        title: "Videos",
        icon: <SlFilm style={ICON_SIZE} />,
        link: "/ShowVideoList",
      },


      {
        title: "Statistics",
        icon: <SlGraph style={ICON_SIZE} />,
        link: "/clubProfile",
      },


    ],
    [],
  );

  let mainButtonLink = "/ReferralRegister";
  let mainButtonText = "Join us";
  if (currentUser.includes("clubMember")) {
    mainButtonLink = "/MemberAccount";
    mainButtonText = "My Account";
  }

  return (
    <>
      <MainTemplate
        mainTitle="跑动英伦"
        subTitle="爱生活, 爱运动, 爱微笑"
        menuItems={menuItems}
        mainButtonLink={mainButtonLink}
        photoFolderName="ClubCover"
        mainButtonText={mainButtonText}
      />
    </>
  );
}

const Home = React.memo(HomeComponent);

export default Home;
