import React from "react";
import { Modal, Button } from "rsuite";
import { FaCopy, FaLinkedin, FaFacebook, FaShareAlt, FaTwitter } from "react-icons/fa"; // Added FaTwitter
import { useNavigate } from "react-router-dom";

const ShareModal = ({ show, onHide }) => {
  const navigate = useNavigate();

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    alert("Link copied to clipboard!");
  };

  const handleShareLinkedIn = () => {
    const linkedInUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(document.title)}&summary=${encodeURIComponent(document.querySelector('meta[name="description"]').content)}&source=${encodeURIComponent(window.location.hostname)}`;
    window.open(linkedInUrl, "_blank");
  };

  const handleShareFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`;
    window.open(facebookUrl, "_blank");
  };

  const handleShareTwitter = () => { // Added handleShareTwitter function
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(document.title)}`;
    window.open(twitterUrl, "_blank");
  };

  const handleNavigatorShare = () => {
    if (navigator.share) {
      navigator.share({
        title: document.title,
        text: document.querySelector('meta[name="description"]').content,
        url: window.location.href,
      }).catch((error) => console.error('Error sharing:', error));
    } else {
      alert("Navigator share is not supported on this browser.");
    }
  };

  return (
    <Modal open={show} onClose={onHide}>
      <Modal.Header>
        <Modal.Title>Share</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Button onClick={handleCopyLink} appearance="primary" block>
          <FaCopy style={{ marginRight: '8px' }} /> Copy Link
        </Button>
        <Button onClick={handleShareLinkedIn} appearance="ghost" block>
          <FaLinkedin style={{ marginRight: '8px' }} /> Share on LinkedIn
        </Button>
        <Button onClick={handleShareFacebook} appearance="ghost" block>
          <FaFacebook style={{ marginRight: '8px' }} /> Share on Facebook
        </Button>
        <Button onClick={handleShareTwitter} appearance="ghost" block>
          <FaTwitter style={{ marginRight: '8px' }} /> Share on Twitter
        </Button>
        <Button onClick={handleNavigatorShare} appearance="ghost" block>
          <FaShareAlt style={{ marginRight: '8px' }} /> Share via Navigator
        </Button>
        {/* Add more share buttons as needed */}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} appearance="subtle">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShareModal;