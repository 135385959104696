import React, { lazy, Suspense, useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import EventCheckOut from "./components/3_eventDB/EventCheckOut";
import { CurrentUserContext } from "./components/6_contextDB/UserContext";
//import { BiArrowBack, BiUser } from "react-icons/bi";
import Home from "./components/16_pages/Home";
import "rsuite/dist/rsuite.min.css";
import { handleUserRole } from "./components/0_authentication/handleUserRole";
import { getDatabase, ref, onValue } from "firebase/database";

import { ToastProvider } from "./components/40_Toaster/ToastContext";
import { handleUpdateMetadata } from "./components/23_Linkedin/handleUpdateMetadata";
import { getFunctions } from "firebase/functions";
import Header from "./components/5_Header/Header";

import firebaseapp from "./components/0_authentication/firebase";
export default function AppTest() {
  const [currentUser, setCurrentUser] = React.useState([]);
  const [businessUser, setBusinessUser] = React.useState([]);
  const [businessDB, setBusinessDB] = React.useState();
  const [userDetail, setUserDetail] = React.useState({});
  const [stripeMemberList, setStripeMemberList] = React.useState([]);
  const [businessList, setBusinessList] = React.useState([]);

  const db = getDatabase();
  const stripeCustomersReference = ref(db, "StripeCustomers");
  const clubBusinessDBReference = ref(db, "ClubBusinessDBMain");
  const functions = getFunctions(firebaseapp);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [hideHeader, setHideHeader] = useState(false); // State to control header visibility

  const Basket = lazy(() => import("./components/34_Shop/Basket"));
  const MemberProfile = lazy(() =>
    import("./components/29_MemberDirectory/MemberProfile"),
  );
  const MortgagePayment = lazy(() =>
    import("./components/32_Mortgage/MortgagePayment"),
  );
  const updatePaymentMethod = lazy(() =>
    import("./components/2_memberDB/ClubUpdatePaymentMethod"),
  );  
  const EventListAll = lazy(() =>
    import("./components/3_eventDB/EventListAll"),
  );
  const ActivityWall = lazy(() =>
    import("./components/31_ActivityWall/ActivityWall"),
  );
  const PropertyIndex = lazy(() =>
    import("./components/35_Property/PropertyIndex"),
  );

  const BusinessCases = lazy(() =>
    import("./components/9_businessDB/BusinessCases"),
  );

  const PointsUpdater = lazy(() =>
    import("./components/33_RewardPoints/PointsUpdater"),
  );
  const MemberProfileEdit = lazy(() =>
    import("./components/29_MemberDirectory/MemberProfileEdit"),
  );
  const ProductCheckoutPage = lazy(() =>
    import("./components/34_Shop/ProductPreCheckOut"),
  );
  const SellerList = lazy(() => import("./components/34_Sellers/SellerList"));

  const ClubMemberLoginPage = lazy(() =>
    import("./components/2_memberDB/ClubMemberLoginPage"),
  );
  const ClubFeedBack = lazy(() =>
    import("./components/12_Profile/ClubFeedBack"),
  );
  const LinkedInCallback = lazy(() =>
    import("./components/23_Linkedin/LinkedInCallback"),
  );

  const ClubVolunteer = lazy(() =>
    import("./components/28_volunteer/ClubVolunteer"),
  );

  const ClubProfile = lazy(() =>
    import("./components/13_ClubStat/ClubProfile"),
  );
  const CheckoutPage = lazy(() =>
    import("./components/36_GooglePay/CheckoutPage"),
  );
  const LinkedInPost = React.lazy(() =>
    import("./components/23_Linkedin/LinkedInPost"),
  );
  const EventFeedback = React.lazy(() =>
    import("./components/3_eventDB/EventFeedback"),
  );
  const RewardPointsBoard = React.lazy(() =>
    import("./components/33_RewardPoints/RewardPointsBoard"),
  );
  const RunConverter = React.lazy(() =>
    import("./components/26_tools/RunConverter"),
  );
  const EPCSearch = React.lazy(() =>
    import("./components/35_Property/EPCSearch"),
  );
  const BusinessRegister = React.lazy(() =>
    import("./components/9_businessDB/BusinessRegister"),
  );
  const Guide = React.lazy(() => import("./components/30_Guide/Guide"));
  const BlogList = React.lazy(() => import("./components/14_blog/BlogList"));
  const Blog = React.lazy(() => import("./components/14_blog/Blog"));
  const ClubCancelMembership = React.lazy(() =>
    import("./components/2_memberDB/ClubCancelMembership"),
  );
  const ClubBenefit = React.lazy(() =>
    import("./components/2_memberDB/ClubBenefit"),
  );
  const BusinessHandleProfile = lazy(() =>
    import("./components/9_businessDB/BusinessHandleProfile"),
  );

  const EventCancelBooking = lazy(() =>
    import("./components/3_eventDB/EventCancelBooking"),
  );
  const MemberAccount = lazy(() =>
    import("./components/12_Profile/MemberAccount"),
  );
  const MentorMain = lazy(() => import("./components/20_Mentoring/MentorMain"));

  const BusinessLogin = lazy(() =>
    import("./components/9_businessDB/BusinessLogin"),
  );

  const BusinessProjectList = lazy(() =>
    import("./components/9_businessDB/BusinessProjectList"),
  );
  const BusinessProject = lazy(() =>
    import("./components/9_businessDB/BusinessProject"),
  );

  const Event = lazy(() => import("./components/3_eventDB/Event"));

  const TaskDB = lazy(() => import("./components/8_taskDB/TaskDB"));
  const EventCalendar = lazy(() =>
    import("./components/3_eventDB/EventCalendar"),
  );
  const ProductCategories = lazy(() =>
    import("./components/34_Shop/ProductCategories"),
  );
  const BusinessMemberDB = lazy(() =>
    import("./components/9_businessDB/BusinessMemberDB"),
  );
  const EditRunnerResult = lazy(() =>
    import("./components/5_runnerDB/EditRunnerResult"),
  );

  const ReferralData = lazy(() =>
    import("./components/23_Referal/ReferralData"),
  );
  const EmailControl = lazy(() => import("./components/15_Email/EmailControl"));
  const ReferralBoard = lazy(() =>
    import("./components/23_Referal/ReferralBoard"),
  );
  const Pdf = lazy(() => import("./components/26_tools/Pdf"));
  const Toolsmain = lazy(() => import("./components/26_tools/ToolsMain.js"));

  const TFLSearch = lazy(() => import("./components/26_tools/TFLSearch"));

  const LinkedinImageShare = lazy(() =>
    import("./components/23_Linkedin/LinkedinImageShare"),
  );
  const LinkedInEventShare = lazy(() =>
    import("./components/23_Linkedin/LinkedInEventShare"),
  );
  const ClubMain = lazy(() => import("./components/2_memberDB/ClubMain"));

  const CompanySearch = lazy(() =>
    import("./components/26_tools/CompanySearch"),
  );
  const GovDataSearch = lazy(() =>
    import("./components/26_tools/GovDataSearch"),
  );
  const AddEvent = lazy(() => import("./components/3_eventDB/AddEvent"));

  const MemberDirectory = lazy(() =>
    import("./components/29_MemberDirectory/MemberDirectory"),
  );

  const EventList = lazy(() => import("./components/3_eventDB/EventList"));
  const EventMap = lazy(() => import("./components/3_eventDB/EventMap"));
  const LinkedInProfile = lazy(() =>
    import("./components/23_Linkedin/LinkedInProfile"),
  );
  const AddRunResult = lazy(() =>
    import("./components/5_runnerDB/AddRunResult"),
  );

  const GooglePayMain = lazy(() =>
    import("./components/36_GooglePay/GooglePayMain"),
  );

  const ImportDB = lazy(() => import("./components/4_importDB/ImportDB"));

  //const stripDB = lazy(() => import("./components/2_memberDB/StripeDB"));

  const ShowPhotoList = lazy(() =>
    import("./components/17_images/ShowPhotoList"),
  );
  const AddBlog = lazy(() => import("./components/14_blog/AddBlog"));

  const ShowVideoList = lazy(() =>
    import("./components/18_Youtube/ShowVideoList"),
  );
  const RunnerResult = lazy(() => import("./components/5_runnerDB/RunResult"));

  const MentorRegistration = lazy(() =>
    import("./components/20_Mentoring/MentorRegistration"),
  );
  const EmailSubscribe = lazy(() =>
    import("./components/15_Email/EmailSubscribe"),
  );
  const BusinessMain = lazy(() =>
    import("./components/9_businessDB/BusinessMain"),
  );

  const PropertyMain = lazy(() =>
    import("./components/35_Property/PropertyMain"),
  );

  const ProductMain = lazy(() => import("./components/34_Shop/ProductMain"));

  const ProductOnlineList = lazy(() =>
    import("./components/3_eventShop/ProductOnlineList"),
  );
  //const EmailControl = lazy(() => import("./components/archive/EmailControl"));
  const ReferralRegister = lazy(() =>
    import("./components/23_Referal/ReferralRegister"),
  );
  const EmailUnsubscribe = lazy(() =>
    import("./components/15_Email/EmailUnsubscribe"),
  );
  const CrawlByClass = lazy(() => import("./components/26_tools/CrawlByClass"));
  const TwitterPost = lazy(() => import("./components/25_Tweeter/TwitterPost"));

  const PropertyAuction = lazy(() =>
    import("./components/35_Property/PropertyAuction"),
  );
  const AddProduct = lazy(() => import("./components/34_Shop/AddProduct"));
  const PropertyPriceData = lazy(() =>
    import("./components/35_Property/PropertyPriceData"),
  );
  const PropertyRental = lazy(() =>
    import("./components/35_Property/PropertyRental"),
  );
  const PropertyEthnic = lazy(() =>
    import("./components/35_Property/PropertyEthnic"),
  );
  const ProductList = lazy(() => import("./components/34_Shop/ProductList"));

  const ProductDetails = lazy(() =>
    import("./components/34_Shop/ProductDetails"),
  );
  const SellerDetails = lazy(() =>
    import("./components/34_Sellers/SellerDetails"),
  );

  const QuillEditor = lazy(() =>
    import("./components/37_ QuillEditor/QuillEditor"),
  );

  const EditProduct = lazy(() => import("./components/34_Shop/EditProduct"));

  const routes = [
    { path: "/PropertyRental", component: PropertyRental },
    { path: "/SellerList", component: SellerList },
    { path: "/EditProduct", component: EditProduct },
    { path: "/QuillEditor", component: QuillEditor },
    { path: "/ProductCheckoutPage", component: ProductCheckoutPage },
    { path: "/Basket", component: Basket },
    { path: "/updatePaymentMethod", component: updatePaymentMethod },
    { path: "/product-details/:productId", component: ProductDetails },
    { path: "/brand/:brandName", component: SellerDetails },
    { path: "/ProductOnlineList", component: ProductOnlineList },
    { path: "/ProductList", component: ProductList },
    { path: "/ProductCategories", component: ProductCategories },
    { path: "/PointsUpdater", component: PointsUpdater },
    { path: "/CrawlByClass", component: CrawlByClass },
    { path: "/AddProduct", component: AddProduct },
    { path: "/PropertyIndex", component: PropertyIndex },
    { path: "/PropertyPriceData", component: PropertyPriceData },
    { path: "/PropertyMain", component: PropertyMain },
    { path: "/EPCSearch", component: EPCSearch },
    { path: "/PropertyEthnic", component: PropertyEthnic },
    { path: "/RewardPointsBoard", component: RewardPointsBoard },
    { path: "/clubmain", component: ClubMain },
    { path: "/MortgagePayment", component: MortgagePayment },
    { path: "/MemberProfileEdit", component: MemberProfileEdit },
    { path: "/ActivityWall", component: ActivityWall },
    { path: "/Guide", component: Guide },
    { path: "/ClubVolunteer", component: ClubVolunteer },
    { path: "/ClubFeedBack", component: ClubFeedBack },
    { path: "/LinkedInCallback", component: LinkedInCallback },
    { path: "/EventListAll", component: EventListAll },
    { path: "/ClubProfile", component: ClubProfile },
    { path: "/BusinessRegister", component: BusinessRegister },
    { path: "/ImportDB", component: ImportDB },
    { path: "/runconverter", component: RunConverter },
    { path: "/MentorMain", component: MentorMain },
    { path: "/BusinessHandleProfile", component: BusinessHandleProfile },
    { path: "/LinkedInPost", component: LinkedInPost },
    { path: "/eventCancelBooking/:eventId", component: EventCancelBooking },
    { path: "/eventfeedback/:eventId", component: EventFeedback },
    { path: "/ClubMemberLogin", component: ClubMemberLoginPage },
    { path: "/ClubBenefit", component: ClubBenefit },
    { path: "/ClubCancelMembership", component: ClubCancelMembership },
    { path: "/blog", component: BlogList },
    { path: "/blog/*", component: Blog },
    { path: "/MemberAccount", component: MemberAccount },
    { path: "/businesslogin", component: BusinessLogin },
    { path: "/businesslist", component: BusinessProjectList },
    { path: "/businesslist/*", component: BusinessProject },
    { path: "/eventcalendar", component: EventCalendar },
    { path: "/events/*/checkout", component: EventCheckOut },
    { path: "/events/*", component: Event },
    { path: "/edit-product/:productId", component: EditProduct },
    { path: "/memberdirectory/:referenceCode", component: MemberProfile },
    { path: "/ReferralBoard", component: ReferralBoard },
    { path: "/AddRunResult", component: AddRunResult },
    { path: "/ReferralData", component: ReferralData },
    { path: "/TaskDB", component: TaskDB },
    { path: "/CompanySearch", component: CompanySearch },
    { path: "/businessmain", component: BusinessMain },
    { path: "/TwitterPost", component: TwitterPost },
    { path: "/events", component: EventList },
    { path: "/AddEvent", component: AddEvent },
    { path: "/Pdf", component: Pdf },
    //{ path: "/ClubMemberHandleProfile", component: ClubMemberHandleProfile },
    { path: "/RunnerResult", component: RunnerResult },
    { path: "/MentorRegistration", component: MentorRegistration },
    { path: "/businessDB", component: BusinessMemberDB },
    { path: "/AddBlog", component: AddBlog },
    { path: "/BusinessCases", component: BusinessCases },

    { path: "/LinkedInProfile", component: LinkedInProfile },
    { path: "/EditRunnerResult", component: EditRunnerResult },
    { path: "/LinkedInEventShare", component: LinkedInEventShare },
    {
      path: "/ShowPhotoList",
      component: () => (
        <Suspense fallback={<div>Loading...</div>}>
          <ShowPhotoList photoFolderName="HomePage" />
        </Suspense>
      ),
    },
    {
      path: "/ShowVideoList",
      component: () => (
        <Suspense fallback={<div>Loading...</div>}>
          <ShowVideoList videoFolderName="HomePage" />
        </Suspense>
      ),
    },
    { path: "/EmailSubscribe", component: EmailSubscribe },
    { path: "/productmain", component: ProductMain },
    { path: "/EmailUnsubscribe", component: EmailUnsubscribe },
    { path: "/EventMap", component: EventMap },
    { path: "/GovDataSearch", component: GovDataSearch },
    { path: "/TFLSearch", component: TFLSearch },
    { path: "/Toolsmain", component: Toolsmain },
    { path: "/EmailControl", component: EmailControl },
    { path: "/LinkedinImageShare", component: LinkedinImageShare },
    { path: "/ReferralRegister", component: ReferralRegister },
    { path: "/PropertyAuction", component: PropertyAuction },
    { path: "/GooglePayMain", component: GooglePayMain },
    { path: "/CheckoutPage", component: CheckoutPage },
    { path: "/ClubRegister", component: ReferralRegister },
    { path: "/MemberDirectory", component: MemberDirectory },
  ];

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set isMobileView based on window width
      setIsMobileView(window.innerWidth < 768);
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  useEffect(() => {
    let isMounted = true;

    // Cache localStorage values to avoid multiple synchronous calls
    const customerDetails = {
      ID: localStorage.getItem("customerID"),
      email: localStorage.getItem("customerEmail"),
      firstName: localStorage.getItem("customerFirstName"),
      familyName: localStorage.getItem("customerFamilyName"),
      wechatID: localStorage.getItem("customerWechatID"),
    };

    const emailSetStripe = new Set([]);
    const emailSetBusiness = new Set();

    const processStripeData = (results) => {
      results.forEach((item) => {
        emailSetStripe.add(item.email.toLowerCase());
      });

      if (isMounted) {
        setStripeMemberList(Array.from(emailSetStripe));

        const userDetail =
          results.find((item) => item.id === customerDetails.ID) || null;
        setUserDetail(userDetail ? [userDetail] : []);
        if (userDetail?.email) {
          handleUserRole(userDetail.email, setCurrentUser);
        }

        if (!customerDetails.email && userDetail?.email) {
          localStorage.setItem("customerEmail", userDetail.email);
        }

        if (userDetail?.name) {
          const nameParts = userDetail.name.split(" ");
          const firstName = nameParts.length > 0 ? nameParts[0] : "";
          const familyName =
            nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";

          if (firstName && !customerDetails.firstName) {
            const capitalizedFirstName =
              firstName.charAt(0).toUpperCase() +
              firstName.slice(1).toLowerCase();
            localStorage.setItem("customerFirstName", capitalizedFirstName);
          }

          if (familyName && !customerDetails.familyName) {
            const capitalizedFamilyName =
              familyName.charAt(0).toUpperCase() +
              familyName.slice(1).toLowerCase();
            localStorage.setItem("customerFamilyName", capitalizedFamilyName);
          }
        }
        if (
          !customerDetails.wechatID &&
          userDetail?.wechatID &&
          userDetail.wechatID.toLowerCase() !== "na"
        ) {
          localStorage.setItem("customerWechatID", userDetail.wechatID);
        }
        //if database wechat id is empty and localstorage is not. update databse
        if (
          !userDetail?.wechatID &&
          customerDetails.wechatID &&
          userDetail &&
          userDetail.id
        ) {
          handleUpdateMetadata(
            functions,
            userDetail.id,
            "wechatID",
            customerDetails.wechatID,
          );
        }
      }
    };

    const stripeUnsubscribe = onValue(stripeCustomersReference, (snapshot) => {
      const data = snapshot.val() || [];
      processStripeData(data);
    });

    const processBusinessData = (resultObj) => {
      Object.values(resultObj || {}).forEach((item) => {
        emailSetBusiness.add(item?.EmailAddress?.toLowerCase());
      });

      if (isMounted) {
        setBusinessList(Array.from(emailSetBusiness));
      }
    };

    onValue(clubBusinessDBReference, (snapshot) => {
      const data = snapshot.val();
      processBusinessData(data);
    });

    // Cleanup function to unsubscribe and handle isMounted flag
    return () => {
      isMounted = false;
      stripeUnsubscribe(); // Unsubscribe from Firebase listeners
    };
  }, []); // Empty dependency array for running once on mount

  //  const navItems1 = [
  //    { path: "/eventcalendar", label: "Calendar" },
  //];
  //  const navItems = [];

  return (
    <>
      <ToastProvider>
        {" "}
        <CurrentUserContext.Provider
          value={[
            currentUser,
            setCurrentUser,
            businessUser,
            setBusinessUser,
            businessDB,
            setBusinessDB,
            userDetail,
            stripeMemberList,
            businessList,
            setUserDetail,
            setStripeMemberList,
            setBusinessList,
            setHideHeader,
          ]}
        >
          {!hideHeader && <Header />}
          <Routes>
            <Route path="/" element={<Home />} />

            {routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <Suspense fallback={<div></div>}>
                    <route.component />
                  </Suspense>
                }
              />
            ))}
          </Routes>{" "}
        </CurrentUserContext.Provider>
      </ToastProvider>
    </>
  );
}
