// ToastContext.js
import React, { createContext, useContext, useState } from "react";
import ToastContainer from "./ToastContainer";

const ToastContext = createContext();

export const useToasts = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const addToast = (content, options = {}) => {
    const id = new Date().getTime(); // Unique ID for the toast
    setToasts((toasts) => [...toasts, { id, content, ...options }]);
  };

  const removeToast = (id) => {
    setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
  };

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer toasts={toasts} removeToast={removeToast} />
    </ToastContext.Provider>
  );
};
