const EventAddGoogleCalendar = (editData) => {
  // Ensure editData is not undefined
  if (!editData) {
    console.error("editData is undefined");
    return "#"; // Provide a fallback or error URL
  }

  const formatTimeForGoogleCalendar = (date, time) => {
    // Ensure both date and time are defined
    if (!date || !time) {
      console.error("Date or time is undefined", date, time);
      return "";
    }

    // Format the date and time to ISO string without assuming UTC
    const dateTime = new Date(`${date}T${time}:00`); // Removed 'Z' to use local timezone
    if (isNaN(dateTime)) {
      console.error("Invalid date or time format");
      return "";
    }
    return dateTime.toISOString().replace(/-|:|\.\d{3}/g, "");
  };

  // Use EventDate and StartTime from editData
  const startTime = formatTimeForGoogleCalendar(
    editData.EventDate,
    editData.StartTime,
  );

  // Check if startTime was successfully generated
  if (!startTime) {
    console.error(
      "Invalid start time. Unable to generate Google Calendar URL.",
    );
    return "#"; // Provide a fallback or error URL
  }

  // Assuming the event ends 1 hour after the start time for simplicity
  const endTime = new Date(
    new Date(`${editData.EventDate}T${editData.StartTime}:00`).getTime() +
      60 * 60 * 1000,
  )
    .toISOString()
    .replace(/-|:|\.\d{3}/g, "");

  // Format description with a hyperlink
  const descriptionWithLink = `Event Details: ${editData.EventDiscription}<br><a href='
  https://cukrun.app/events/${editData.EntryID}'>Event Link</a>`;

  const params = new URLSearchParams({
    action: "TEMPLATE",
    text: editData.EventTitle,
    dates: `${startTime}/${endTime}`,
    location: editData.Address,
    details: descriptionWithLink,
    sf: true,
    output: "xml",
  });

  return `https://www.google.com/calendar/render?${params.toString()}`;
};

export default EventAddGoogleCalendar;
