import { useEffect } from "react";
import Axios from "axios"; // Assuming you're using Axios for HTTP requests.
//import src/components/17_images/cukrun_logo_white.png
import EventAddGoogleCalendar from "../3_eventDB/EventAddGoogleCalendar";

const EventCheckOutEmail = ({
  firstName,
  familyName,
  email,
  eventIdentifier,
  wechatID,
  price,
  eventQueryData,
  location,
  eventCCEmail, // Assuming you need this from the parent component
}) => {
  const sendEmailURL =
    "https://us-central1-cukrun-1.cloudfunctions.net/sendMail";

  const logoUrl =
    "https://cukrun.app/static/media/cukrun_nb_logo.c0f9729fe177dcc9384c.png";
  useEffect(() => {
    const fromEmail = "跑动英伦<ukcukrun@gmail.com>";
    const receiverEmail = email;
    const ccEmail = "cukrun2017@gmail.com";
    const bccEmail = eventCCEmail;
    const subjectEmail = "Event registration confirmation from 跑动英伦";
    const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(
      email,
    )}`;
    const calendarUrl = EventAddGoogleCalendar(eventQueryData); // Assuming this function returns the URL

    const contentEmail = `
          <div style="max-width: 600px">
              <img src="${logoUrl}" alt="CUKRUN logo" width="220" height="auto" style="display: block; margin: 0 auto; text-decoration: none; outline: none; border: none;" />            
              <div style="font-size: 14px; color: black; text-align: left; background-color: white; padding: 20px;">
                  Dear <strong>${firstName} ${familyName}</strong><br /><br />
                  You have successfully registered <strong>${eventIdentifier}</strong><br /><br />
                  <strong>Add to calendar: </strong><a href="${calendarUrl}">Add to Calendar</a><br /><br />    
                  <strong>WechatID</strong>:${wechatID}<br />
                  <strong>Email:</strong> ${email}<br />
                  <strong>Price (not refundable):</strong> £${price}<br />
                  <strong>Date:</strong> ${eventQueryData.EventDate}<br />
                  <strong>Start time:</strong> ${eventQueryData.StartTime}<br />
                  <strong>Location: </strong>${eventQueryData.Address}<br />
                  <strong>Event Link: </strong><a href="https://cukrun.app/${location.pathname}">Link</a><br /><br />
                  <img src="${qrCodeUrl}" alt="Your QR Code" style="margin-top: 20px;" />
                  <p>Please present this QR code for event checkin.</p>
                          
                  Looking forward to seeing you.<br/><br/>
                  CUKRUN
                  <div style="text-align: left; margin-top: 32px;">
                      <a href="https://cukrun.app/ReferralRegister" target="_blank" style="background-color: black; border-radius: 3px; border: 0; padding: 5px 25px; color: #fff; font-size: 14px; line-height: 24px; text-decoration: none; display: inline-block; font-weight: 15px;">
                          Join CUKRUN club
                      </a>
                  </div><br/><br/><br/><br/>
              </div>
          </div>
      `;

    Axios.get(sendEmailURL, {
      params: {
        fromEmail,
        receiverEmail,
        ccEmail,
        bccEmail,
        subjectEmail,
        contentEmail,
      },
    });
  }, [
    firstName,
    familyName,
    email,
    eventIdentifier,
    wechatID,
    price,
    eventQueryData,
    location,
    eventCCEmail,
    sendEmailURL,
  ]);

  return null; // This component doesn't render anything visibly.
};

export default EventCheckOutEmail;
