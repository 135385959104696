import React, { useMemo, useEffect,useContext } from "react";
import { Button, Modal } from "rsuite";
import { Link, useNavigate } from "react-router-dom";
import "../../App.css";
import { CurrentUserContext } from "../6_contextDB/UserContext";
import ShowPhotoRTDB from "../17_images/ShowPhotoRTDB";
import SubscribeButton from "../15_Email/SubscribeButton";
import ClubMemberLogin from "../2_memberDB/ClubMemberLogin";
function MainTemplate(props) {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useContext(CurrentUserContext);
  const {
    menuItems,
    mainButtonText,
    mainButtonLink,
    photoFolderName,
    mainTitle,
    subTitle,
  } = props;


  const [isMobileView, setIsMobileView] = React.useState(
    window.innerWidth < 768,
  );

  const [openLogin, setOpenLogin] = React.useState(false);

  const menuItemElements = useMemo(() => {
    // Group items into chunks of 3
    const rows = [];
    for (let i = 0; i < menuItems.length; i += 1) {
      const row = menuItems.slice(i, i + 1);
      rows.push(row);
    }

    return rows.map((row, rowIndex) => (
      <div
        key={`row-${rowIndex}`}
        style={{
          display: 'flex',
          width: '100%',
          gap: '12px',
          marginBottom: '12px',
        }}
      >
        {row.map((item, index) => (
          <div
            key={`item-${rowIndex}-${index}`}
            style={{
              flex: 1,
              minWidth: 0, // Prevents flex items from overflowing
            }}
          >
            <Link
              to={item.link}
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "white",
                border: "1px solid #e5e5e5",
                borderRadius: "12px",
                padding: "16px 25px",
                width: "100%",
                color: "#222222",
                fontSize: "13px",
                textDecoration: "none",
                transition: "all 0.2s ease",
                ':hover': {
                  transform: "translateY(-2px)",
                  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                }
              }}
            >
              {item.icon && (
                <span style={{ marginRight: "20px" }}>{item.icon}</span>
              )}
              <span style={{ flex: 1 }}>{item.title}</span>
            </Link>
          </div>
        ))}
      </div>
    ));
  }, [menuItems]); // Only recompute if menuItems changes
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const ButtonGroup = () => (
    <>
      <Button 
        appearance="primary" 
        style={{width: "150px"}}
        onClick={() => navigate("/ReferralRegister")}
      >
        Join us
      </Button>
      &nbsp;
      <Button 
        appearance="default" 
        style={{width: "100px"}}
        onClick={() => {
          if (currentUser.length === 0) {
            setOpenLogin(true);
          } else {
            navigate("/MemberAccount");
          }
        }}
      >
        My Account
      </Button>
      &nbsp;
      <SubscribeButton />

      <br/><br/>
    </>
  );

  const MenuSection = () => (
    <div
      className="whiteSmokeBackground"
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      {menuItemElements}
    </div>
  );

  return (
    <>
      {isMobileView ? (
        <div className="PanelStyle centered">
          <ShowPhotoRTDB photoFolderName={photoFolderName} />
          <div
            style={{
              marginBottom: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              alignItems: "left",
            }}
          >
            <span
              style={{
                fontSize: "35px",
                color: "black",
                marginTop: "10px",
                textAlign: "left",
              }}
            >
              {mainTitle}
            </span>
          </div>
          <ButtonGroup />
          <MenuSection />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "top",
            width: "100%",
            marginTop: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "50%",
              paddingLeft: "200px",
              overflow: "hidden",
              justifyContent: "center",
              alignItems: "top",
            }}
          >
            <div style={{ maxWidth: "400px" }}>
              <ShowPhotoRTDB photoFolderName={photoFolderName} />
            </div>
          </div>
          <div
            style={{
              width: "50%",
              paddingRight: "150px",
              marginLeft: "30px",
              marginBottom: "10px"
            }}
          >
            <div>
              <span style={{ fontSize: "45px" }}>{mainTitle}</span>
              <hr />
            </div>
            <ButtonGroup />
            <MenuSection />
          </div>
        </div>
      )}
            <Modal open={openLogin} onClose={() => setOpenLogin(false)}>
        <Modal.Header>
          <Modal.Title>Member Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ClubMemberLogin onSuccess={() => navigate("/MemberAccount")} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MainTemplate;
