import { getDatabase, ref, onValue } from "firebase/database";

const db = getDatabase();

export const getFirebaseData = (path) => {
  return new Promise((resolve, reject) => {
    const reference = ref(db, path);

    onValue(
      reference,
      (snapshot) => {
        const result = snapshot.val();
        resolve(result);
      },
      (error) => {
        reject(error);
      },
    );
  });
};
