import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import AppTest from "./AppTest";
import "./custom-theme.css";

// PWA install handling
let deferredPrompt;

if (process.env.NODE_ENV === 'production') {
  // Only register service worker in production
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', async () => {
      try {
        const registration = await navigator.serviceWorker.register('/service-worker.js', {
          scope: '/'
        });
        console.log('ServiceWorker registration successful with scope:', registration.scope);
      } catch (error) {
        console.error('ServiceWorker registration failed:', error);
        if (error instanceof TypeError) {
          console.error('Service Worker script evaluation failed. Please check the script for syntax errors.');
        }
      }
    });
  }

  // Handle install prompt
  window.addEventListener('beforeinstallprompt', (e) => {
    console.log('Install prompt captured');
    e.preventDefault();
    deferredPrompt = e;
  });

  // Log successful installation
  window.addEventListener('appinstalled', (e) => {
    console.log('PWA was installed');
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppTest />
    </BrowserRouter>
  </React.StrictMode>,
);

reportWebVitals();
