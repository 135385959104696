// ToastContainer.js
import React, { useEffect } from "react";
import "./Toast.css";
const Toast = ({ id, content, removeToast, type = "success" }) => {
  return (
    <div className={`toast ${type}`} onClick={() => removeToast(id)}>
      <span className="message">{content}</span>
      <span
        className="close-button"
        onClick={(e) => {
          e.stopPropagation();
          removeToast(id);
        }}
      >
        ✖️
      </span>
    </div>
  );
};

const ToastContainer = ({ toasts, removeToast }) => {
  useEffect(() => {
    const timers = toasts.map(
      (toast) => setTimeout(() => removeToast(toast.id), 10000), // Remove toast after 5 seconds
    );

    return () => {
      timers.forEach(clearTimeout); // Clear timers on cleanup
    };
  }, [toasts, removeToast]);

  return (
    <div className="toast-container">
      {toasts.map((toast) => (
        <div key={toast.id} className={`toast ${toast.type || ""}`}>
          <div className="message">{toast.content}</div>
          <button
            className="close-button"
            onClick={() => removeToast(toast.id)}
          >
            &times;
          </button>
        </div>
      ))}
    </div>
  );
};

export default ToastContainer;
