import React, { useEffect, useState, useMemo } from "react";
import { getFirebaseData } from "../22_Firebase/GetFirebaseData";

const ShowPhotoRTDB = React.memo(({ photoFolderName }) => {
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      const result = await getFirebaseData(photoFolderName);
      if (result && !isCancelled) {
        setImageUrls(Object.values(result));
      }
    };

    fetchData();

    return () => {
      isCancelled = true; // Cancel the fetch operation if the component unmounts
    };
  }, [photoFolderName]);

  // Memoize the image elements
  const images = useMemo(() => {
    return imageUrls.map((imageUrl, index) => (
      <img
        key={index}
        src={imageUrl}
        alt={`${photoFolderName}-${index}`}
        //  loading="lazy"
        style={{ maxWidth: "100%", height: "auto", margin: "0 auto" }}
      />
    ));
  }, [imageUrls, photoFolderName]);

  return <div>{images}</div>;
});

export default ShowPhotoRTDB;
