import { useEffect } from "react";
import Axios from "axios"; // Assuming you're using Axios for HTTP requests.

const EventCheckOutReferralEmail = ({
  firstName,
  familyName,
  eventIdentifier,
  location,
  eventCCEmail, // Assuming you need this from the parent component
  referralEmail,
  referralName,
}) => {
  const sendEmailURL =
    "https://us-central1-cukrun-1.cloudfunctions.net/sendMail";

  useEffect(() => {
    // Replace with your environment variable or configuration
    const fromEmail = "跑动英伦<ukcukrun@gmail.com>";
    const receiverEmail = referralEmail;
    const ccEmail = "cukrun2017@gmail.com";
    const bccEmail = eventCCEmail;
    const subjectEmail = " Referral success confirmation from 跑动英伦";
    const logoUrl =
      "https://cukrun.app/static/media/cukrun_nb_logo.c0f9729fe177dcc9384c.png";

    const contentEmail = `
        <div style="max-width: 600px">
        <img src="${logoUrl}" alt="CUKRUN logo" width="220" height="auto" style="display: block; margin: 0 auto; text-decoration: none; outline: none; border: none;" />            
           
          <div style="font-size: 14px; color: black; text-align: left; background-color: white; padding: 20px;">
            Hello <strong>${referralName}</strong><br /><br />
            You have successfully referred <strong>${firstName} ${familyName}</strong> to sign up <strong>${eventIdentifier}</strong><br /><br />
            <strong>Event Link: </strong><a href="https://cukrun.app/${location.pathname}">Link</a><br /><br />
            Appreciate your support to the CUKRUN family.<br/><br/>
            CUKRUN
          </div>
        </div>
      `;

    Axios.get(sendEmailURL, {
      params: {
        fromEmail,
        receiverEmail,
        ccEmail,
        bccEmail,
        subjectEmail,
        contentEmail,
      },
    });
  }, []); // Removed dependencies to only send the email once on component mount

  return null; // This component doesn't render anything visibly.
};

export default EventCheckOutReferralEmail;
