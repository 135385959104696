import { Button, Modal, Input } from 'rsuite';
import { useState } from 'react';
import { getDatabase, ref, push } from "firebase/database";

const SubscribeButton = () => {
  const db = getDatabase();
  const ClubEmailSubscribe = ref(db, "ClubEmailSubscribe");

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    email: ''
  });
  const [isEmailValid, setIsEmailValid] = useState(false);

  const handleEmailChange = (value) => {
    setFormValue({ ...formValue, email: value });
    setIsEmailValid(validateEmail(value));
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSubmit = async (event) => {
    console.log(formValue)
    event.preventDefault();
    setLoading(true);
    try {
      await push(ClubEmailSubscribe, { Email: formValue.email });
      handleClose();
    } catch (error) {
      console.error('Error submitting email:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button 
        appearance="default"
        style={{ width: "100px" }}
        onClick={handleOpen}
      >
        Subscribe
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Subscribe to Event Notifications</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <Input 
              name="email" 
              placeholder="Enter your email"
              type="email"
              value={formValue.email}
              onChange={handleEmailChange}
   
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            appearance="primary" 
            type="submit"
            loading={loading}
            disabled={!isEmailValid}
            onClick={handleSubmit}
            style={{ 
              backgroundColor: !isEmailValid ? '#cccccc' : undefined,
              color: !isEmailValid ? '#666666' : undefined 
            }}
          >
            Subscribe
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SubscribeButton;