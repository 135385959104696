// stripeUtils.js
import { httpsCallable } from "firebase/functions";

export const handleUpdateMetadata = (
  functions,
  customerID,
  fieldName,
  fieldValue,
) => {
  if (fieldName && fieldValue) {
    const stripeUpdateCustomer = httpsCallable(
      functions,
      "stripeUpdateCustomer",
    );
    const data = {
      customerID: customerID,
      fieldName: fieldName,
      fieldValue: fieldValue,
    };

    stripeUpdateCustomer(data)
      .then((result) => {
        console.log("done");
        // alert("Metadata successfully updated!");
      })
      .catch((error) => {
        console.error("Error updating metadata:", error);
        alert("Failed to update metadata.");
      });
  }
};
