export const handleUserRole = (userEmail, setCurrentUser) => {
  const mentorVolunteer = [
    "wzsun1023@hotmail.com",
    "yiting.shen@gmail.com",
    "762661301@qq.com",
    "91258lz@gmail.com",
    "cukrun2017@gmail.com",
    "akylai138@gmail.com",
  ];

  let userArray = ["clubMember"];

  if (userEmail === "cukrun2017@gmail.com") {
    userArray.push("clubAdmin");
    userArray.push("runningAdmin");
  }
  if (mentorVolunteer.includes(userEmail)) {
    userArray.push("mentorVolunteer");
  }
  if (userEmail.toLowerCase() === "richardsundl@icloud.com") {
    userArray.push("runningAdmin");
  }
  if (userEmail.toLowerCase() === "jialinzhao67@gmail.com") {
    userArray.push("sectary");
  }

  setCurrentUser(userArray);
};
