import { useLocation, Link } from "react-router-dom";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState, useEffect, useContext } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import firebaseapp from "../0_authentication/firebase";
import { Form, Button, Schema, Message, Panel } from "rsuite";
import { CurrentUserContext, EventContext } from "../6_contextDB/UserContext";
import { getDatabase, ref, onValue, push } from "firebase/database";
import { FaCheckCircle } from "react-icons/fa";
import EventCheckOutEmail from "./EventCheckOutEmail";
import EventCheckOutReferralEmail from "./EventCheckOutReferralEmail";
import StatusMessages, { useMessages } from "../7_sharedCodes/StatusMessages";
import { useToasts } from "../40_Toaster/ToastContext";

const db = getDatabase();
const stripeCustomersReference = ref(db, "StripeCustomers");
const { StringType } = Schema.Types;
const model = Schema.Model({
  email: StringType()
    .isEmail("Please enter a valid email address.")
    .isRequired("This field is required."),
});

function TextField(props) {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-3`}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
}

export default function EventCheckOut(props) {
  const [success, setSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const functions = getFunctions(firebaseapp);
  const [message, setMessage] = useState();
  //const [eventRegister, setEventRegister] = React.useState([]);
  //const [eventRegisterEmails, setEventRegisterEmails] = useState();
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [familyName, setFamilyName] = React.useState("");
  const [wechatID, setWechatID] = React.useState("");
  const [buttonSubmitIND, setButtonSubmitIND] = React.useState(false);
  const [identity, setIdentity] = React.useState();
  const [isCardComplete, setCardComplete] = useState(false);
  const [messages, addMessage] = useMessages();
  const [buttonDisabled, setButtonDisabled] = useState(true); // Define a state variable and its initial value
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEmailRegistered, setIsEmailRegistered] = useState(false);
  const [currentUser] = useContext(CurrentUserContext);
  // New state variables for discount code
  const [discountCode, setDiscountCode] = useState("");
  const [discountValid, setDiscountValid] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const location = useLocation();
  const [
    eventQueryData,
    eventIdentifier,
    eventCCEmail,
    stripeMemberList,
    businessList,
    eventRegister,
    eventRegisterEmails,
    existingAttendNo,
    referralEmail,
    referralKey,
    referralName,
  ] = useContext(EventContext);
  const eventFull =
    Number(existingAttendNo) >= Number(eventQueryData?.EventMaxNo);
  const [price, setPrice] = React.useState(0);
  const stripeCreatePaymentIntent = httpsCallable(
    functions,
    "stripeCreatePaymentIntent",
    //"stripePayment",
  );
  const { addToast } = useToasts();

  const calculatePrice = (email, discountCodeValid, discountPercentage) => {
    let calculatedPrice = parseFloat(eventQueryData.PriceNonMember);

    if (stripeMemberList.includes(email.toLowerCase())) {
      calculatedPrice = parseFloat(eventQueryData.PriceMember);
    } else if (businessList.includes(email.toLowerCase())) {
      calculatedPrice = parseFloat(eventQueryData.PriceEnterpreneur);
    }

    if (discountCodeValid) {
      calculatedPrice -= calculatedPrice * (discountPercentage / 100);
    }

    return calculatedPrice;
  };

  const handleDiscountCodeChange = (e) => {
    setDiscountCode(e);
  };

  const applyDiscount = () => {
    // Check if discount code and percentage exist in the data
    if (eventQueryData.EventDiscountCode && eventQueryData.EventDiscountPC) {
      if (discountCode === eventQueryData.EventDiscountCode) {
        const discountPercentage = eventQueryData.EventDiscountPC; // e.g., 0.1 for 10%
        const newPrice = calculatePrice(email, true, discountPercentage);

        setPrice(newPrice);
        setDiscountValid(true);

        addToast(
          `Discount code successfully applied: You've received a ${discountPercentage}% discount. The new total is £${newPrice.toFixed(
            2,
          )}.`,
        );
      } else {
        setDiscountValid(false);
        addToast("Invalid discount code.");
      }
    } else {
      // Handle the case where there is no discount code or percentage in the data
      addToast("Discount code functionality is currently unavailable.");
    }
  };

  useEffect(() => {
    // Only recalculate the price if a discount has not been applied
    if (!discountValid) {
      const newPrice = calculatePrice(email, discountValid, discountAmount);
      setPrice(newPrice);
    }
  }, [email, discountValid, discountAmount]);

  useEffect(() => {
    const fetchData = async () => {
      const customerEmail = localStorage.getItem("customerEmail");
      if (customerEmail) {
        setEmail(customerEmail);
        handleEmail(customerEmail);
      }
      //const customerName = localStorage.getItem("customerName");
      //setName(customerName);
      const customerFirstName = localStorage.getItem("customerFirstName");
      if (customerFirstName) {
        setFirstName(customerFirstName);
      }
      const customerFamilyName = localStorage.getItem("customerFamilyName");
      if (customerFamilyName) {
        setFamilyName(customerFamilyName);
      }
      setName((customerFirstName || "") + " " + (customerFamilyName || ""));
      const customerWechatID = localStorage.getItem("customerWechatID");
      if (customerWechatID) {
        setWechatID(customerWechatID);
      }
    };
    fetchData();
  }, [eventRegisterEmails]);
  //}, [eventRegisterEmails]);
  const validateEmail = (email) => {
    // Simple regex for validating an email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleEmail = (emailInput) => {
    setEmail(emailInput);
    // Validate the email format
    if (!validateEmail(emailInput)) {
      //   setMessage("Please enter a valid email address.");
      return;
    }
    const isFormValid = validateForm(emailInput);

    if (!isFormValid) return;

    const emailLower = emailInput?.toLowerCase();
    let identity = "Other Guest";
    let newPrice = eventQueryData.PriceNonMember;

    if (stripeMemberList.includes(emailLower)) {
      newPrice = eventQueryData.PriceMember;
      identity = "Club member";
    } else if (businessList.includes(emailLower)) {
      newPrice = eventQueryData.PriceEnterpreneur;
      identity = "EnterpreneurApp user";
    }

    // Update the identity regardless
    setIdentity(identity);

    // If a discount has been applied, just update the message without changing the price
    if (discountValid) {
      setMessage(`As a ${identity}, your discounted price is £${price}.`);
    } else {
      // If no discount has been applied, update the price and the message

      setPrice(newPrice);
      //     setMessage(`As a ${identity}, the price is £${newPrice}.`);
      setMessage(`As a ${identity}, the price is £${newPrice}.`, {});
    }
  };

  const handleManualSubmit = () => {
    const newEntryObj = {
      EntryID: Date.now(),
      Email: email,
      Name: firstName + " " + familyName,
      WechatID: wechatID,
      Identity: identity,
      FirstName: firstName,
      FamilyName: familyName,
    };
    const reference = ref(db, "ClubEventsAttendee/" + eventIdentifier);
    push(reference, newEntryObj);
    setMessage("Registration successful!"); // Assuming this function is synchronous
  };
  const handleFreeRegister = async () => {
    // const newEntryObj = {
    const newEntryObj = {
      EntryID: Date.now(),
      Email: email,
      Name: firstName + " " + familyName,
      WechatID: wechatID,
      Identity: identity,
      FirstName: firstName,
      FamilyName: familyName,
    };

    if (referralKey && referralEmail && referralName) {
      // If there is a referral, set the referral data
      newEntryObj.referralEmail = referralEmail;
      newEntryObj.referralKey = referralKey;
      newEntryObj.referralName = referralName;
    }

    localStorage.setItem("customerEmail", email);
    localStorage.setItem("customerFirstName", firstName);
    localStorage.setItem("customerFamilyName", familyName);
    localStorage.setItem("customerWechatID", wechatID);
    const newFeed = {
      Time: Date.now(),
      Name: firstName + " " + familyName,
      Action: "registered",
      Feed: eventIdentifier,
      Link: "events/" + location.pathname.split("/").pop(),
      Email: email,
    };

    const reference = ref(db, "ClubEventsAttendee/" + eventIdentifier);
    //push to activity wall
    const ClubActivities = ref(db, "ClubActivities/");

    try {
      window.alert("Register successfully!");
      // Use await here since push returns a Promise
      await push(reference, newEntryObj);
      //push to activity wall
      await push(ClubActivities, newFeed);

      setSuccess(true); // Assuming this function is synchronous
      props.setRefreshRegisterData((prevState) => !prevState);
    } catch (error) {
      // Handle any errors that occur during push
      addToast("Registration failed: " + error.message); // Update the message accordingly
      setSuccess(false); // Assuming this function is synchronous
    }
  };

  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    setButtonDisabled(true);

    if (parseInt(price) === 0) {
      handleFreeRegister();
      return; // Return early to exit the function
    }

    if (!elements) {
      addToast(
        "Card elements is not loaded. Please try again or use another card.",
      );
      setButtonDisabled(false);
      setIsSubmitting(false);

      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      addToast(error.message);
      setButtonSubmitIND(false); // Resetting to false in case of an error.
      setButtonDisabled(false);
      setIsSubmitting(false);

      return;
    } else {
      //addToast("Payment method has been created.");
    }

    const { id } = paymentMethod;

    const data = {
      amount: price * 100,
      id,
      name: firstName + " " + familyName,
      email: email,
      description: eventIdentifier,
      productName: eventIdentifier,
    };

    try {
      const result = await stripeCreatePaymentIntent(data);

      if (!result.data || !result.data.payment) {
        addToast(
          "Sorry, the payment was not successful. Please try another card.",
        );
        setButtonDisabled(false);
        setIsSubmitting(false);

        return;
      }

      // If the payment requires additional actions, like 3D Secure authentication
      if (result.data.payment.status !== "succeeded") {
        const confirmResult = await stripe.confirmCardPayment(
          result.data.payment.client_secret,
        );

        if (confirmResult.error) {
          addToast(
            "Authentication failed. Please try again or use a different card.",
          );
          setButtonDisabled(false);
          setIsSubmitting(false);

          return;
        }
        if (
          confirmResult.paymentIntent &&
          confirmResult.paymentIntent.status === "succeeded"
        ) {
          handleFreeRegister();
        } else {
          addToast("Payment could not be completed. Please try again.", {
            type: "warning",
          });
          setButtonDisabled(false);
          setIsSubmitting(false);
        }
      } else if (result.data.payment.status === "succeeded") {
        handleFreeRegister();
      } else {
        addToast("Unexpected payment status. Please contact support.");
        setButtonDisabled(false);
        setIsSubmitting(false);
      }
    } catch (error) {
      // addMessage("An error occurred during the payment process.");
      //addToast("Payment could not be completed. Please try again.");
      addToast("Payment Failed. " + error.message, {
        type: "warning",
      });
      setIsSubmitting(false);
      setButtonSubmitIND(false);
      setButtonDisabled(false);
    }

    // Setting buttonSubmitIND to true after all operations involving the CardElement are done.
    setButtonSubmitIND(true);
  };

  useEffect(() => {
    const storeCustomerID = async () => {
      await onValue(stripeCustomersReference, (snapshot) => {
        const result = snapshot.val();
        const filteredData = result?.filter((item) => item.email === email);
        if (filteredData?.length !== 0) {
          const customerID = filteredData[0].id;
          localStorage.setItem("customerID", customerID);
        }
      });
    };
    storeCustomerID();
  }, [success]);
  useEffect(() => {
    const inputCheck =
      firstName.length > 0 &&
      familyName.length > 0 &&
      email.length > 0 &&
      wechatID.length > 0;

    if (inputCheck && !isEmailRegistered) {
      // If price is 0, enable the button
      if (parseInt(price) === 0) {
        setButtonDisabled(false);
        setIsSubmitting(false);
      }
      // If price is greater than 0 and card is complete, enable the button
      else if (parseInt(price) > 0 && isCardComplete) {
        setButtonDisabled(false);
        setIsSubmitting(false);
      }
      // Else, disable the button
      else {
        setButtonDisabled(true);
        setButtonSubmitIND(false); // Resetting to false in case of an error.
        setIsSubmitting(false);
      }
    }
    // If not all input fields are filled, or email is not valid, disable the button
    else {
      setButtonDisabled(true);
    }
  }, [
    firstName,
    familyName,
    email,
    wechatID,
    isCardComplete,
    price,
    isEmailRegistered,
  ]);

  const validateForm = (emailInput) => {
    const emailLower = emailInput.toLowerCase();
    const isEmailRegistered = eventRegisterEmails.includes(emailLower);

    if (isEmailRegistered) {
      setMessage("This email has been registered.");
    } else {
      setMessage("");
    }

    setIsEmailRegistered(isEmailRegistered);

    return !isEmailRegistered;
  };
  // <StatusMessages messages={messages} />

  return (
    <Panel>
      {!success && message && message.length > 0 ? (
        <>
          <Panel className="notification-style">{message}</Panel>

          <br />
        </>
      ) : null}
      {currentUser.includes("clubAdmin") && !success ? (
        <>
          <Button block onClick={handleManualSubmit}>
            Admin Input
          </Button>
          <br />
        </>
      ) : null}
      {!success && elements && !buttonSubmitIND ? (
        <>
          <Form
            model={model}
            fluid
            onSubmit={(e) => {
              handleSubmit(e);
            }}
            className="detail-style"
            style={{ padding: "0px" }}
          >
            <TextField
              name="email"
              label="Email*"
              onChange={(e) => {
                handleEmail(e);
              }}
              value={email || ""}
              required
              style={{ padding: "15px" }}
            />
            <TextField
              name="firstName"
              label="First Name * "
              onChange={(e) => setFirstName(e)}
              value={firstName || ""}
              required
              style={{ padding: "15px" }}
            />
            <TextField
              name="familyName"
              label="Family Name * "
              onChange={(e) => setFamilyName(e)}
              value={familyName || ""}
              required
              style={{ padding: "15px" }}
            />
            <TextField
              name="WechatID"
              label="WechatID *"
              onChange={(e) => setWechatID(e)}
              value={wechatID || ""}
              required
              style={{ padding: "15px" }}
            />

            {eventQueryData.EventDiscountCode && !discountValid && price > 0 ? (
              <>
                {" "}
                <Form.ControlLabel>Discount Code</Form.ControlLabel>
                <Form.Group style={{ display: "flex", alignItems: "center" }}>
                  <Form.Control
                    name="discountCode"
                    type="text"
                    onChange={handleDiscountCodeChange}
                    value={discountCode}
                    style={{ flex: 1, marginRight: "10px", padding: "20px" }} // Add some space between the input and the button
                  />
                  <Button
                    appearance="link"
                    onClick={applyDiscount}
                    style={{ flexShrink: 0, color: "grey" }}
                  >
                    Apply
                  </Button>
                </Form.Group>
              </>
            ) : null}
            {price > 0 && email ? (
              <>
                <Panel bordered>
                  <CardElement
                    options={{ hidePostalCode: true }}
                    onChange={(event) => {
                      setCardComplete(event.complete);
                    }}
                  />
                </Panel>
                <br />
              </>
            ) : null}

            <span style={{ color: "grey", marginTop: "5px", fontSize: "12px" }}>
              Please be advised that purchases are final and non-refundable.
              Additionally, they cannot be transferred to another person.
            </span>
            <br />
            <footer className="footer">
              <div className="PanelStyle centered">
                {eventFull ? (
                  <Button
                    disabled
                    style={{
                      width: "100%",
                      maxWidth: "500px",
                      backgroundColor: "grey",
                      color: "white",
                    }}
                  >
                    Registrations Full - Stay Tuned!
                  </Button>
                ) : (
                  <>
                    <Button
                      type="submit"
                      appearance="primary"
                      loading={isSubmitting} // Add loading state
                      style={{
                        width: "100%",
                        backgroundColor: isSubmitting
                          ? "grey"
                          : buttonDisabled
                          ? "grey"
                          : "#006a6b",
                        color: "white",
                      }}
                      disabled={buttonDisabled}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </Button>{" "}
                  </>
                )}
              </div>
            </footer>
          </Form>

          <br />
        </>
      ) : null}{" "}
      {success ? (
        <>
          <Panel className="notification-style">
            Registration Success! Confirmation email has been sent to your
            mailbox.
          </Panel>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "40px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaCheckCircle size="80px" color="darkgreen" />
            </div>
            <hr />
          </div>

          {currentUser?.includes("clubMember") ? (
            <div style={{ textAlign: "center" }}>
              <Link to="/MemberAccount">
                <Button
                  appearance="link"
                  style={{
                    color: "black",
                    width: "100%",
                  }}
                >
                  Check your member account.
                </Button>
              </Link>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <Link to="/events">
                <Button
                  appearance="link"
                  style={{
                    width: "100%",
                    color: "black",
                  }}
                >
                  Find Out More events.
                </Button>
              </Link>
            </div>
          )}

          <br />
        </>
      ) : null}
      {success && (
        <EventCheckOutEmail
          firstName={firstName}
          familyName={familyName}
          email={email}
          eventIdentifier={eventIdentifier}
          wechatID={wechatID}
          price={price}
          eventQueryData={eventQueryData}
          location={location}
          eventCCEmail={eventCCEmail}
        />
      )}
      {success && referralEmail && (
        <EventCheckOutReferralEmail
          firstName={firstName}
          familyName={familyName}
          eventIdentifier={eventIdentifier}
          location={location}
          eventCCEmail={eventCCEmail}
          referralEmail={referralEmail}
          referralName={referralName}
        />
      )}
    </Panel>
  );
}
