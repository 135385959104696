import React, { useEffect, useState, useContext } from "react";
import { getDatabase, ref, onValue, set, push } from "firebase/database";

import { Button, Panel } from "rsuite";
import { Form, Input, Message } from "rsuite";
import Axios from "axios";
import { FaCheckCircle } from "react-icons/fa";
//import { handleUpdateMetadata } from "../23_Linkedin/handleUpdateMetadata";
import { useNavigate } from "react-router-dom";
import { CurrentUserContext } from "../6_contextDB/UserContext";
import { handleUserRole } from "../0_authentication/handleUserRole";
//import { ConsoleView } from "react-device-detect";

const db = getDatabase();

const sendEmailURL = "https://us-central1-cukrun-1.cloudfunctions.net/sendMail";
const stripeCustomersReference = ref(db, "StripeCustomers");
function ClubMemberLogin({ onSuccess }) {
  const [
    currentUser,
    setCurrentUser,
    businessUser,
    setBusinessUser,
    businessDB,
    setBusinessDB,
    userDetail,
    stripeMemberList,
    businessList,
    setUserDetail,
    setStripeMemberList,
    setBusinessList,
  ] = useContext(CurrentUserContext);
  const [stripeMemberDB, setStripeMemberDB] = React.useState();
  const [email, setEmail] = React.useState();
  const [message, setMessage] = React.useState();
  const [customerID, setCustomerID] = React.useState();
  const [verifiedEmail, setVerifiedEmail] = React.useState(false);
  // const [stripeMemberList, setStripeMemberList] = React.useState();
  const [inputKey, setInputKey] = React.useState();
  const [loginComplete, setLoginComplete] = React.useState(false);
  const [submitGetKeyInd, setSubmitGetKeyInd] = React.useState(false);
  //const clubBusinessDBReference = ref(db, "ClubBusinessDBMain");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStripeData = async () => {
      await onValue(stripeCustomersReference, (snapshot) => {
        const result = snapshot.val();
        //console.log(result);
        setStripeMemberDB(result);
        const storedCustomerID = localStorage.getItem("customerID");
        const filteredData2 = result?.filter(
          (item) => item.email === "cukrun2017@gmail.com",
        );

        const filteredData = result?.filter(
          (item) => item.id === storedCustomerID,
        );
        if (filteredData?.length !== 0) {
          handleUserRole(filteredData[0].email, setCurrentUser);
          setLoginComplete("true");
          setMessage("We have confirmed you are a member of CUKRUN club.");
        }

        let emailList = [];
        result?.map(
          (item) => (emailList = emailList.concat(item.email.toLowerCase())),
        );
        setStripeMemberList(emailList);
      });
    };
    fetchStripeData();
  }, []);

  const handleBack = () => {
    navigate(-1);
  };
  const handleSubmit = () => {
    if (stripeMemberList.includes(email.toLowerCase())) {
      const filteredData = stripeMemberDB.filter(
        (item) => item.email.toLowerCase() === email.toLowerCase(),
      );
      // console.log("filteredData", filteredData);
      //console.log("customerID is ", filteredData[0].id);
      setUserDetail(filteredData);
      setCustomerID(filteredData[0].id);

      setMessage("Our database shows you are a member of CUKRUN club.");
      setVerifiedEmail(true);
    } else {
      setMessage("This email is not in our CUKRUN Member database.");
    }
  };
  useEffect(() => {
    const handleSendKey = async () => {
      if (customerID) {
        handleSubmitGetKey();
      }
    };
    handleSendKey();
  }, [customerID]);
  const handleSubmitKey = () => {
    if (inputKey.trim() === customerID) {
      setMessage("Your member login is complete! ");
      handleUserRole(email, setCurrentUser);
      localStorage.setItem("customerID", customerID);
      localStorage.setItem("customerEmail", email);
      //  localStorage.setItem("customerEmail", email);
      if (userDetail[0]?.wechatID) {
        localStorage.setItem("customerWechatID", userDetail[0].wechatID);
      }
      if (userDetail[0]?.name) {
        const nameParts = userDetail[0].name.split(" ");
        const firstName = nameParts.length > 0 ? nameParts[0] : "";
        const familyName =
          nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";
        if (firstName) {
          const capitalizedFirstName =
            firstName.charAt(0).toUpperCase() +
            firstName.slice(1).toLowerCase();
          localStorage.setItem("customerFirstName", capitalizedFirstName);
        }
        if (familyName) {
          const capitalizedFamilyName =
            familyName.charAt(0).toUpperCase() +
            familyName.slice(1).toLowerCase();
          localStorage.setItem("customerFamilyName", capitalizedFamilyName);
        }
      }

      setLoginComplete("true");
      if (onSuccess) {
        onSuccess();
      }
    } else {
      setMessage("App key input is incorrect!");
    }
  };

  const handleSubmitGetKey = () => {
    const receiverEmail = email;

    const fromEmail = "跑动英伦<ukcukrun@gmail.com>";
    const ccEmail = "";
    const bccEmail = "cukrun2017@gmail.com";
    const subjectEmail = "CUKRUN APP key ! Don't share with others!";
    const logoUrl =
      "https://cukrun.app/static/media/cukrun_nb_logo.c0f9729fe177dcc9384c.png";

    const contentEmail = `
      <div style="max-width: 600px">
      <img src="${logoUrl}" alt="CUKRUN logo" width="220" height="auto" style="display: block; margin: 0 auto; text-decoration: none; outline: none; border: none;" />            
      
        <div style="font-size: 13px; color: black; text-align: left; background-color: white; padding: 20px;">

          Your CUKRUN APP key is as follows:
    <br /><br />    <br /><br />
    <span style="font-size: 30px; color: black;"> ${customerID} </span>
    <br /><br />    <br /><br />
    <strong>  CUKRUN</strong>
    <br /><br />
        </div>
      </div>
    `;

    Axios.get(sendEmailURL, {
      params: {
        fromEmail,
        receiverEmail,
        ccEmail,
        bccEmail,
        subjectEmail,
        contentEmail,
      },
    });
    setMessage("We have sent you the KEY via email. Please check your email.");
    setSubmitGetKeyInd(true);
  };

  return (
    <>
      <div>
        {verifiedEmail === false && !loginComplete ? (
          <>
            <Form onSubmit={(event) => handleSubmit(event)}>
              <Input
                type="email"
                name="email"
                placeholder="Registered email - member only"
                required
                style={{ width: "100%", marginRight: "10px" }}
                onChange={(e) => setEmail(e)}
              />
              <br />

              <Button
                appearance="primary"
                style={{
                  width: "30%",
                  marginLeft: "auto",
                  display: "block"
                }}
                type="submit"
              >
                Submit
              </Button>
            </Form>
            <br />
          </>
        ) : null}

        {verifiedEmail === true && !loginComplete ? (
          <>
            <Form onSubmit={(event) => handleSubmitKey(event)}>
              <Input
                type="password"
                name="key"
                placeholder="Enter your APP key"
                required
                style={{ width: "100%", marginRight: "10px" }}
                onChange={(e) => setInputKey(e)}
              />

              <br />

              <Button
                appearance="primary"
                style={{
                  width: "30%",
                  marginLeft: "auto",
                  display: "block"
                }}
                type="submit"
              >
                Submit
              </Button>
            </Form>
            <br />
          </>
        ) : null}
        {message && !loginComplete ? (
          <>
            <Panel className="notification-style">{message}</Panel>
          </>
        ) : null}
        {loginComplete ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "50px",
                marginBottom: "50px",
              }}
            >
              <FaCheckCircle size="50px" color="green" />
            </div>
          
          </>
        ) : null}
      </div>
    </>
  );
}
export default ClubMemberLogin;
