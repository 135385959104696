import React from "react";
import { Navbar, Nav } from "rsuite";
import { FaBars, FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./Header.css";
import { useLocation } from "react-router-dom";
import { SlArrowLeft, SlShareAlt, SlUser } from "react-icons/sl";
import cukrun_nb_logo from "../17_images/cukrun_nb_logo.png";
import ShareModal from "./ShareModal"; // Adjust the import path as necessary
import MenuModal from "./MenuModal"; // Adjust the import path as necessary

const Header = () => {
  const isWeChat = /MicroMessenger/i.test(navigator.userAgent);

  const navigate = useNavigate();
  const location = useLocation();
  const handleBrandClick = () => {
    navigate(-1);
  };

  const handleHomeClick = () => {
    navigate("/");
  };
  const handleShare = () => {
    const copiedLink = "https://cukrun.app" + location.pathname;
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(copiedLink)
        .then(() => {
          alert("Link has been copied: " + copiedLink);
        })
        .catch((err) => {
          alert("Unable to copy link: ", err);
          // Handle the error appropriately, e.g., show an error message to the user
        });
    } else {
      alert("Clipboard API not available");
      // Handle the case where the Clipboard API is not supported
    }
  };

  const [showShareModal, setShowShareModal] = React.useState(false);
  const [showMenuModal, setShowMenuModal] = React.useState(false);

  const handleShareClick = () => {
    setShowShareModal(true);
  };

  const handleMenuClick = () => {
    setShowMenuModal(true);
  };

  const handleCloseShareModal = () => {
    setShowShareModal(false);
  };

  const handleCloseMenuModal = () => {
    setShowMenuModal(false);
  };

  return (
    <>
      <Navbar className="custom-navbar">
        <div className="navbar-content">
          <Nav className="nav-items">
            <Nav.Item className="brand" onClick={handleBrandClick}>
              <FaArrowLeft />{" "}
            </Nav.Item>
            <Nav.Item className="brand" onClick={handleShareClick}>
              <SlShareAlt />{" "}
            </Nav.Item>
         
      
          </Nav>
          <Nav pullRight className="nav-items">
            <Nav.Item className="brand" onClick={handleHomeClick}>
              <img src={cukrun_nb_logo} alt="Logo" className="logo" />
            </Nav.Item>
          </Nav>
        </div>
      </Navbar>
      <ShareModal show={showShareModal} onHide={handleCloseShareModal} />
       <br />
    </>
  );
};

export default Header;
